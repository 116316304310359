<!-- MapView.vue -->
<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Locations</h1>
                <p>Dashboard > Maps > Locations</p>
            </div>

            <div class="split-layout">
                <div class="map-container">
                    <v-card class="map-wrapper">
                        <GMapMap :center="center" :zoom="currentZoom" map-type-id="terrain" :options="mapOptions"
                            ref="mapRef" @click="onMapClick">
                            <GMapMarker v-for="(driver, index) in drivers" :key="'driver-' + index"
                                :icon="{ url: driver.icon }" :position="driver.position"
                                @click="toggleDriverInfo(driver, index)"
                                :class="{ 'selected-marker': selectedDriver === driver }">
                                <GMapInfoWindow :opened="driver.infoWindowOpen" :options="infoWindowOptions">
                                    <!-- Info Window Content -->
                                    <div class="info-window-container">
                                        <div class="driver-header">
                                            <div class="driver-avatar">
                                                <v-avatar size="60">
                                                    <v-img :src="driver.imagePath" :alt="driver.name" />
                                                </v-avatar>
                                            </div>
                                            <div class="driver-info">
                                                <h3 class="driver-name">
                                                    <span class="mr-3">
                                                        {{ driver.name }}
                                                    </span>
                                                    <Pulse :color="getDriverStatusColor(driver.status)">
                                                    </Pulse>
                                                </h3>
                                                <div class="vehicle-info">
                                                    <v-icon size="18">mdi-car</v-icon>
                                                    <span>{{ driver.vehicle }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <v-divider class="my-3"></v-divider>

                                        <div class="current-trip">
                                            <h4 class="section-title">
                                                <v-icon color="primary">mdi-map-marker-path</v-icon>
                                                Current Trip Details
                                            </h4>
                                            <div class="tourists-list">
                                                <div v-for="(tourist, index) in driver.tourists" :key="index"
                                                    class="tourist-card">
                                                    <div class="tourist-header">
                                                        <div class="tourist-name">
                                                            <v-icon size="20" color="primary">mdi-account</v-icon>
                                                            <span>{{ tourist.name }}</span>
                                                        </div>
                                                        <v-chip size="small" :color="getStatusColor(tourist.status)">
                                                            {{ tourist.status }}
                                                        </v-chip>
                                                    </div>

                                                    <div class="tourist-details">
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-map-marker</v-icon>
                                                            <span>{{ tourist.destination }}</span>
                                                        </div>
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-clock-outline</v-icon>
                                                            <span>{{ tourist.pickupTime }}</span>
                                                        </div>
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-account-group</v-icon>
                                                            <span>{{ tourist.adults }} Adults, {{ tourist.children }}
                                                                Children</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </GMapInfoWindow>
                            </GMapMarker>
                        </GMapMap>
                    </v-card>
                </div>

                <div class="info-container">

                    <!-- Selected Driver Details Card -->
                    <v-expand-transition>
                        <v-card v-if="selectedDriver" elevation="1" class="mb-4">
                            <v-card-title class="py-4 px-6 position-relative">
                                <div class="d-flex justify-space-between align-center w-100">
                                    <div class="d-flex align-center">
                                        <v-avatar size="48" class="mr-4">
                                            <v-img :src="selectedDriver.imagePath" :alt="selectedDriver.name"></v-img>
                                        </v-avatar>
                                        <div>
                                            <div class="text-h6">
                                                <span class="mr-3">{{ selectedDriver.name }}</span>
                                                <Pulse :color="getDriverStatusColor(selectedDriver.status)"></Pulse>
                                            </div>
                                            <div class="text-subtitle-2 text-medium-emphasis">
                                                Last Updated: {{ selectedDriver.lastUpdated }}
                                            </div>
                                        </div>
                                    </div>

                                    <v-btn icon variant="text" size="small" color="medium-emphasis" @click="onMapClick"
                                        class="close-btn">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-card-text class="pa-4">
                                <div class="driver-stats-grid">
                                    <div class="stat-item">
                                        <div class="stat-label">Vehicle</div>
                                        <div class="stat-value">{{ selectedDriver.vehicle }}</div>
                                    </div>
                                    <div class="stat-item">
                                        <div class="stat-label">Today's Trips</div>
                                        <div class="stat-value">{{ selectedDriver.tourists.length }}</div>
                                    </div>
                                    <div class="stat-item">
                                        <div class="stat-label">Status</div>
                                        <v-chip size="small" :color="getDriverStatusColor(selectedDriver.status)">
                                            {{ selectedDriver.status }}
                                        </v-chip>
                                    </div>
                                </div>

                                <v-divider class="my-4"></v-divider>

                                <div class="text-h6 mb-3">Current Passengers</div>
                                <v-timeline density="compact" align="start">
                                    <v-timeline-item v-for="(tourist, index) in selectedDriver.tourists"
                                        :key="'timeline-' + index" :dot-color="getStatusColor(tourist.status)"
                                        size="x-small">
                                        <div class="timeline-content">
                                            <div>{{ tourist.pickupTime }}</div>
                                            <h3>{{ tourist.name }}</h3>
                                            <p>
                                                {{ tourist.destination }}
                                            </p>
                                            <div>
                                                {{ tourist.adults }} Adults, {{ tourist.children }} Children
                                            </div>
                                        </div>
                                    </v-timeline-item>
                                </v-timeline>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>

                    <v-card elevation="1">
                        <v-card-title class="d-flex justify-space-between align-center py-4 px-6">
                            <span class="text-h6">Your Drivers</span>
                            <v-chip color="primary" size="small">
                                {{ activeDrivers }} Active
                            </v-chip>
                        </v-card-title>

                        <v-card-text class="pa-0">
                            <v-list>
                                <v-list-item v-for="(driver, index) in drivers" :key="'driver-list-' + index"
                                    :class="{ 'selected-driver': selectedDriver === driver }"
                                    @click="toggleDriverInfo(driver, index)">
                                    <template v-slot:prepend>
                                        <v-avatar size="40">
                                            <v-img :src="driver.imagePath" :alt="driver.name"></v-img>
                                        </v-avatar>
                                    </template>

                                    <v-list-item-title class="font-weight-medium">
                                        <span class="mr-3">
                                            {{ driver.name }}
                                        </span>
                                        <Pulse :color="getDriverStatusColor(driver.status)">
                                        </Pulse>
                                    </v-list-item-title>

                                    <v-list-item-subtitle class="d-flex align-center mt-1">
                                        <v-icon size="16" class="mr-1">mdi-car</v-icon>
                                        {{ driver.vehicle }}
                                    </v-list-item-subtitle>

                                    <template v-slot:append>
                                        <div class="driver-stats">
                                            <v-chip size="small" v-if="driver.yourDriver" color="primary" class="ml-2">
                                                Your Driver
                                            </v-chip>
                                            <div class="tourist-count mt-2">
                                                <v-icon size="16">mdi-account-group</v-icon>
                                                <span>{{ driver.tourists.length }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import map from '@/assets/styles/map'
import TopNav from '@/components/TopNav.vue'
import Pulse from '@/components/Pulse.vue'

export default {
    name: 'MapView',
    components: {
        TopNav,
        Pulse
    },

    data() {
        return {
            imageCache: new Map(), // Memory cache for images
            mapStyles: JSON.parse(map.customMapStyles),
            center: { lat: 1.290270, lng: 103.851959 },
            currentZoom: 10,
            selectedDriver: null,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: JSON.parse(map.customMapStyles)
            },
            infoWindowOptions: {
                pixelOffset: { width: 0, height: -35 },
                hideCloseButton: true,
            },
            drivers: [
                {
                    name: 'John Doe',
                    position: { lat: 1.290270, lng: 103.851959 },
                    vehicle: 'Toyota Prius',
                    status: 'Inactive',
                    yourDriver: true,
                    infoWindowOpen: false,
                    imagePath: 'https://picsum.photos/200',
                    lastUpdated: '2023-09-09 14:30',
                    tourists: [
                        {
                            name: 'Alice Smith',
                            destination: 'Marina Bay Sands',
                            status: 'Picked Up',
                            adults: 2,
                            children: 1,
                            pickupTime: '15:00'
                        },
                        {
                            name: 'Bob Johnson',
                            destination: 'Sentosa Island',
                            status: 'Waiting',
                            adults: 1,
                            children: 0,
                            pickupTime: '16:30'
                        }
                    ]
                },
                {
                    name: 'Jane Smith',
                    position: { lat: 1.299270, lng: 103.856959 },
                    vehicle: 'Honda Civic',
                    status: 'Active',
                    yourDriver: false,
                    infoWindowOpen: false,
                    imagePath: 'https://picsum.photos/200',
                    lastUpdated: '2023-09-09 14:35',
                    tourists: [
                        {
                            name: 'Charlie Brown',
                            destination: 'Gardens by the Bay',
                            status: 'Waiting',
                            adults: 3,
                            children: 2,
                            pickupTime: '16:00'
                        }
                    ]
                }
            ],
            // Base64 encoded placeholder avatar for fallback
            placeholderAvatar: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI2NjYyIgZD0iTTEyLDRBNCw0IDAgMCwxIDE2LDhBNCw0IDAgMCwxIDEyLDEyQTQsNCAwIDAsMSA4LDhBNCw0IDAgMCwxIDEyLDRNMTIsMTRDMTYuNDIsMTQgMjAsMTUuNzkgMjAsMThWMjBINFYxOEM0LDE1Ljc5IDcuNTgsMTQgMTIsMTRaIiAvPjwvc3ZnPg==`
        }
    },

    computed: {
        activeDrivers() {
            return this.drivers.filter(driver => driver.status === 'Active').length
        }
    },

    async mounted() {
        await this.loadAllMarkers();
    },

    beforeUnmount() {
        // Clear memory cache when component is destroyed
        this.imageCache.clear();
    },

    methods: {
        async loadAllMarkers() {
            await Promise.all(this.drivers.map(async driver => {
                driver.icon = await this.generateMarkerIcon(driver);
            }));
        },

        async loadImageAsBase64(url) {
            // Check memory cache first
            if (this.imageCache.has(url)) {
                return this.imageCache.get(url);
            }

            // Check browser cache using Cache API
            try {
                const cache = await caches.open('driver-images');
                let response = await cache.match(url);

                if (!response) {
                    // If not in cache, fetch and store
                    response = await fetch(url);
                    await cache.put(url, response.clone());
                }

                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        // Store in memory cache
                        this.imageCache.set(url, reader.result);
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error('Error loading image:', error);
                return this.placeholderAvatar;
            }
        },

        async generateMarkerIcon(driver) {
            const selected = this.selectedDriver === driver;
            const isActive = driver.status.toLowerCase() === 'active';
            const statusColor = isActive ? '#4CAF50' : '#FFA726';

            // Get image from cache or load it
            let base64Image;
            try {
                base64Image = await this.loadImageAsBase64(driver.imagePath);
            } catch (error) {
                console.error('Error generating marker:', error);
                base64Image = this.placeholderAvatar;
            }

            // Create a unique ID for each driver's pattern
            const patternId = `profileImage-${driver.name.replace(/\s+/g, '')}-${Date.now()}`;

            const svg = `
                <svg xmlns="http://www.w3.org/2000/svg" 
                     width="${selected ? '69' : '49'}" 
                     height="${selected ? '89' : '69'}"
                     viewBox="0 0 69 89">
                    <defs>
                        <pattern id="${patternId}" 
                            patternUnits="userSpaceOnUse" 
                            width="38" 
                            height="38"
                            patternTransform="translate(5.5, 5.5)">
                            <image href="${base64Image}" 
                                   width="38" 
                                   height="38"
                                   preserveAspectRatio="xMidYMid slice">
                            </image>
                        </pattern>
                    </defs>
                    <g transform="translate(10, 10)">
                        ${selected ? `
                        <path
                            d="M0,24.151A24.311,24.311,0,0,1,24.5,0,24.309,24.309,0,0,1,49,24.151C49,42.264,24.5,69,24.5,69S0,42.264,0,24.151Z"
                            fill="rgba(0,0,0,0.2)"
                            transform="translate(2, 2)"
                        />` : ''}
                        <path
                            d="M0,24.151A24.311,24.311,0,0,1,24.5,0,24.309,24.309,0,0,1,49,24.151C49,42.264,24.5,69,24.5,69S0,42.264,0,24.151Z"
                            fill="#03256c"
                        />
                        <circle cx="24.5" cy="24.5" r="20.5" fill="#fff" />
                        
                        <!-- Profile image using pattern -->
                        <circle 
                            cx="24.5" 
                            cy="24.5" 
                            r="19" 
                            fill="url(#${patternId})"
                        />
                        
                        <!-- Status indicator -->
                        <circle 
                            cx="38" 
                            cy="38" 
                            r="6" 
                            fill="${statusColor}"
                            stroke="#fff"
                            stroke-width="2"
                        />
                    </g>
                </svg>`;

            return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
        },
        async toggleDriverInfo(driver, index) {
            // Store previous driver for animation
            const previousDriver = this.selectedDriver;

            // If clicking the same driver that's already selected
            if (previousDriver === driver) {
                this.closeDriverInfo(driver);
                return;
            }

            // Close previous driver's info window
            if (previousDriver) {
                previousDriver.infoWindowOpen = false;
            }

            this.selectedDriver = driver;

            await this.loadAllMarkers();

            // Add a small delay for smooth transition
            setTimeout(() => {
                // Set new selected driver and open info window

                driver.infoWindowOpen = true;

                // Smooth map transition
                if (this.$refs.mapRef) {
                    const map = this.$refs.mapRef.$mapObject;

                    // First pan to the new location
                    map.panTo(driver.position);

                    // Then zoom in smoothly
                    map.setZoom(15);
                }
            }, 200); // Small delay for smooth driver card transition
        },

        async closeDriverInfo(driver) {
            this.selectedDriver = null;

            await this.loadAllMarkers();

            driver.infoWindowOpen = false;

            // Smooth zoom out
            this.$refs.mapRef.$mapObject.setZoom(this.currentZoom);
            setTimeout(() => {
                this.centerMapOn({ lat: 1.290270, lng: 103.851959 }); // Your default center
            }, 100);
        },

        centerMapOn(position) {
            // Use smooth pan instead of direct center change
            if (this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
                this.$refs.mapRef.$mapObject.panTo(position);
            } else {
                this.center = position;
            }
        },

        onMapClick(event) {
            if (this.selectedDriver) {
                this.closeDriverInfo(this.selectedDriver)
            }
        },

        getStatusColor(status) {
            switch (status.toLowerCase()) {
                case 'picked up':
                    return 'success'
                case 'waiting':
                    return 'warning'
                case 'completed':
                    return 'info'
                default:
                    return 'grey'
            }
        },

        getDriverStatusColor(status) {
            return status.toLowerCase() === 'active' ? 'success' : 'warning'
        }
    }
}
</script>

<style scoped>
/* Add these to your <style> section */
.v-card {
    border-radius: var(--border-radius);
    transition: all 0.3s ease-in-out;
}

.selected-driver {
    background-color: var(--secondary-dark-grey) !important;
    transition: background-color 0.3s ease-in-out;
}

/* Add animation for marker selection */
.selected-marker {
    animation: bounce 0.5s ease-in-out;
}

.gm-style-iw button[title="Close"] {
    display: none !important;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.split-layout {
    display: flex;
    gap: 24px;
}

.map-container,
.info-container {
    flex: 1;
}

.map-container .v-card {
    margin: auto;
}

.map-wrapper {
    aspect-ratio: 1/1;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.driver-stats {
    text-align: right;
}

.tourist-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    color: var(--v-text-secondary);
}

.info-window-container {
    padding: 16px;
    min-width: 300px;
    max-width: 400px;
    background: white;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
}

.driver-header {
    display: flex;
    align-items: center;
    gap: 16px;
}

.driver-info {
    flex: 1;
}

.driver-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 4px 0;
    color: rgb(var(--v-theme-primary));
}

.driver-name span {
    vertical-align: middle;
}

.vehicle-info {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--v-text-secondary);
    font-size: 0.9rem;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 6px;
}

.section-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    color: rgb(var(--v-theme-primary));
    margin-bottom: 16px;
}

.tourists-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tourist-card {
    background-color: var(--secondary-light-grey);
    border-radius: 8px;
    padding: 12px;
}

.tourist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.tourist-name {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
}

.tourist-details {
    display: flex;
    flex-direction: column;
    gap: 6px
}

.tourist-details .detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    color: var(--v-text-secondary);
}

.driver-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 8px;
}

.stat-item {
    text-align: center;
    padding: 12px;
    background-color: var(--secondary-light-grey);
    border-radius: 8px;
}

.stat-label {
    font-size: 0.875rem;
    color: var(--v-text-secondary);
    margin-bottom: 4px;
}

.stat-value {
    font-size: 1.125rem;
    font-weight: 600;
    color: rgb(var(--v-theme-primary));
}

.timeline-content {
    padding: 8px;
    background-color: var(--secondary-light-grey);
    border-radius: 6px;
    margin-left: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .split-layout {
        flex-direction: column;
    }

    .map-wrapper {
        height: unset;
        max-height: 500px;
    }
}

@media (max-width: 768px) {
    .driver-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .info-window-container {
        min-width: 250px;
    }
}

@media (max-width: 480px) {
    .driver-stats-grid {
        grid-template-columns: 1fr;
    }

    .split-layout {
        padding: 12px;
        gap: 16px;
    }
}
</style>